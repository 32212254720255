.method {
    margin-bottom: 90px;
    p {
        margin: 0;
    }
    .section1 {
        >h2 {
            font-weight: normal;
            text-align: center;
            letter-spacing: 0.08em;
            line-height: 1.35;
        }
    }
    .circle-wrap {
        margin: 0 auto;
        position: relative;
        .circle {
            border-radius: 50%;
            background-color: #EADCCF;
            opacity: 0.9;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            // &.-right {
            //     right: 15%;
            //     left: unset;
            // }
            p {
                text-align: center;
                font-size: 16px;
                letter-spacing: 0.08em;
                line-height: 1.25;
            }
        }
    }
    .section-reason {
        >h2 {
            font-weight: normal;
            text-align: center;
            letter-spacing: 0.08em;
            line-height: 1.35;
        }
        .mathod-wrap {
            width: 100%;
            height: 370px;
            margin-bottom: 70px;
            list-style: none;
            padding: 0;
            .mathod-text {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 50%;
            }
            h2 {
                font-size: 22px;
                font-weight: bold;
                margin-top: 0;
                letter-spacing: 0.08em;
                line-height: 1.36;
                color: #F25043;
                margin-bottom: 22px;
            }
            hr {
                background-color: #CECECE;
                border: none;
                height: 1px;
                margin: 20px 0;
            }
            .textbox {
                width: 80%;
                p {
                    letter-spacing: 0.08em;
                    line-height: 1.25;
                    font-size: 16px;
                }
            }
            .title-wrap {
                width: 100%;
                p {
                    font-size: 16px;
                    text-align: left;
                    letter-spacing: 0.08em;
                    line-height: 1.37;
                }
            }
            .mathod-img {
                img {
                    height: 370px;
                }
            }
        }
    }
}


@media (max-width: 767px) {
    .method.container {
        width: 100%;
        padding: 0;
    }
    .method {
        .mainv {
            h1 {
                font-size: 25px;
            }
        }
        .section1 {
            width: 90%;
            margin: 0 auto;
            >h2 {
                font-size: 22px;
                margin: 50px 0 40px 0;
            }
            .circle-wrap {
                width: 100%;
                margin: 0 auto;
                position: relative;
                .circle {
                    margin: 0 auto;
                    width: 280px;
                    height: 280px;
                    border-radius: 50%;
                    background-color: #EADCCF;
                    opacity: 0.9;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;
                    margin: 0 auto 20px auto;
                    // &.-right {
                    //     margin-bottom: 0;
                    // }
                    p {
                        text-align: center;
                        font-size: 14px;
                    }
                    img {
                        width: 50%;
                    }
                }
            }
        }
        .section-reason {
            margin-top: 20px;
            margin-bottom: 500px;
            >h2 {
                margin: 80px 0 40px 0;
                font-size: 22px;
                padding: 0 20px;
            }
            .mathod-wrap {
                width: 100%;
                height: 370px;
                margin-bottom: 70px;
                list-style: none;
                padding: 0;
                margin: 0;
                position: relative;
                &:not(:first-of-type) {
                    margin-top: 500px;
                }
                &.-long {
                    margin-top: 540px;
                }
                .mathod-text {
                    height: 500px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    position: absolute;
                    &:first-of-type {
                        margin-top: 370px;
                    }
                    &.-long {
                        height: 530px;
                    }
                }
                h2 {
                    font-size: 22px;
                    font-weight: bold;
                    margin-top: 0;
                    letter-spacing: 0.08em;
                    line-height: 1.75;
                    color: #F25043;
                    margin-bottom: 22px;
                }
                hr {
                    background-color: #CECECE;
                    border: none;
                    height: 1px;
                    margin: 20px 0;
                }
                .textbox {
                    width: 80%;
                }
                .title-wrap {
                    width: 100%;
                    p {
                        font-size: 16px;
                        text-align: left;
                    }
                }
                .mathod-img {
                    img {
                        width: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}



@media (min-width: 768px) {
    .method {
        .mainv {
            h1 {
                font-size: 32px;
            }
        }
    }
    .section1 {
        height: 525px;
        >h2 {
            font-size: 32px;
            margin: 90px 0 80px 0;
        }
        .circle-wrap {
            width: 80%;
            margin: 0 auto;
            position: relative;
            display: flex;
            .circle {
                margin: 0 auto;
                width: 390px;
                height: 390px;
                // position: absolute;
                // left: 15%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                // &.-right {
                //     right: 15%;
                //     left: unset;
                // }
                p {
                    text-align: center;
                    font-size: 16px;
                }
            }
        }
    }
    .section-reason {
        margin: 20px 85px;
        >h2 {
            margin: 120px 0 80px 0;
            font-size: 32px;
        }
        .mathod-wrap {
            width: 100%;
            height: 370px;
            margin-bottom: 70px;
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 75px;
            .mathod-text {
                height: 370px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 50%;
            }
            h2 {
                font-size: 22px;
                margin-bottom: 22px;
            }
            .textbox {
                width: 80%;
            }
            .title-wrap {
                width: 100%;
                p {
                    font-size: 16px;
                }
            }
        }
    }
}