
.contact {
    .c-mainv {
        padding-top: 100px;
        padding-bottom: 50px;
        > span {
            display: block;
            color: #F25043;
            font-size: 14px;
            text-align: center;
            position: relative;
            letter-spacing: 0.08em;
            line-height: 1.35;
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -28px;
                display: inline-block;
                width: 180px;
                height: 1px; 
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translate(-50%); 
                background-color: #383838; 
              }
        }
        h1 {
            color: #3E3E3E;
            text-align: center;
            margin-top: 64px;
            letter-spacing: 0.08em;
            line-height: 1.35;
        }
        .title-red {
            color: #F25043;
        }
        > p {
            margin-bottom: 75px;
            margin-top: 30px;
            text-align: center;
            font-size: 14px;
            color: #3E3E3E;
            line-height: 1.35;
        }
        .form-group {
            .required {
                color: #F25043;
            }
            text-align: left;
            display: inline-block;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.05em;
            line-height: 1.5;
            margin-bottom: 23px;
            width: 100%;
            margin: 0 auto;
            
            input,select,textarea {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: 1px solid #707070;
                border-radius: 0;
                margin-top: 4px;
                padding: 20px 18px;
                width: 100%;
                box-sizing: border-box; 
            }
            textarea {
                height: 260px;
                resize: none;
            }
            select {
                background: transparent url(../../common/img/select-arrow.png) no-repeat center right 13px/16px 14px;
            }
        }
        .confirm {
            font-size: 16px;
            font-weight: bold;
        }
        .your-consent {
            font-size: 14px;
            font-weight: bold;
            input {
                vertical-align: text-bottom;
            }
            .wpcf7-list-item {
                margin: 0;
            }
        }
        .submit-wrap {
            position: relative;
            -webkit-perspective: 300px;
            perspective: 300px;
            margin: 25px auto 0;
            max-width: 300px;
            height: 60px;
            display: block;
            margin: 0 auto;
            line-height: 60px;
            text-decoration: none;
            transition: all .3s;
            box-sizing: border-box;
            overflow: hidden;
            border: 1px solid #8B7850;
            color: #8B7850;
            background-color: #ffffff;
            
            .wpcf7-submit {
                appearance: none;
                cursor: pointer;
                display: block;
                font-size: 16px;
                height: 100%;
                letter-spacing: 0.05em;
                margin: 0 auto;
                width: 100%;
                border: none;
            }
        }
        .wpcf7-form p {
            margin-bottom: 25px;
        }
    }
}


@media (max-width: 767px) {
    .container {
        width: 100%;
        padding: 0 20px;
    }
    .contact {
        .c-mainv {
            h1 {
                font-size: 30px;
            }
        }
        .form-group {
            width: 100%;
            text-align: left;
        }
        .submit-wrap {
            .wpcf7-submit {
                appearance: none;
                background: #8B7850;
                color: #ffffff;
                cursor: pointer;
                display: block;
                font-size: 15px;
                height: 100%;
                letter-spacing: 0.05em;
                margin: 0 auto;
                width: 100%;
            }
        }
    }
}


@media (min-width: 768px) {
    .contact {
        .c-mainv {
            margin: 0 360px;
            h1 {
                font-size: 32px;
            }
        }
        .form-group {
            font-size: 15px;
            width: 100%;
            input,select,textarea {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: 1px solid #707070;
                border-radius: 0;
                margin-top: 4px;
                padding: 20px 18px;
                width: 100%; 
            }
            select {
                background-position: center right 26px; 
            }
        }
        .submit-wrap {
            width: 200px;
            .wpcf7-submit {
                appearance: none;
                background: inherit;
                color: inherit;
                cursor: pointer;
                display: block;
                font-size: 15px;
                height: 100%;
                letter-spacing: 0.05em;
                margin: 0 auto;
                width: 100%;
            }
            &:before, &:after {
                transition: all .3s;
                box-sizing: border-box;
                position: absolute;
                z-index: -1;
                display: block;
                content: '';
            }
            &:hover {
                color: #fff;
                &:after {
                    top: 0;
                    left: 0;
                    background-color: #8B7850;
                }
            }
            &:after {
                top: -100%;
                left: -100%;
                width: 100%;
                height: 100%;
            }
        }
    }
}