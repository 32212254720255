
.qa {
    .qa-mainv {
        span {
            display: block;
            color: #F25043;
            font-size: 14px;
            text-align: center;
            position: relative;
            letter-spacing: 0.08em;
            line-height: 1.35;
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -28px;
                display: inline-block;
                width: 180px;
                height: 1px; 
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translate(-50%); 
                background-color: #383838; 
              }
        }
        h1 {
            color: #3E3E3E;
            text-align: center;
            margin-top: 64px;
            letter-spacing: 0.08em;
            line-height: 1.35;
            font-weight: normal;
        }
        p {
            text-align: center;
            font-size: 14px;
            color: #3E3E3E;
            line-height: 1.35;
            margin-top: 30px;
        }
    }
    .qa-wrap {
        margin: 0;
        border-top: solid 1px #D6D6D6;
        list-style: none;
        padding-left: 0;
        margin-left: 0;
        &:last-child {
            border-bottom: solid 1px #D6D6D6;
        }
        p {
            margin: 0;
        }
        .q-text {
            font-size: 22px;
            margin-right: 25px;
            font-weight: 600;
            line-height: 1;
        }
        .a-text {
            font-size: 22px;
            margin-right: 25px;
            font-weight: 600;
            line-height: 1;
            position: absolute;
        }
    }
    .qa-item {
        padding: 27px 0;
        display: flex;
        line-height: 1.35;
        position: relative;

        p {
            padding-left: 41px;
        }
    }
    .a-item {
        display: none;
        letter-spacing: 0.08em;
    }
    .q-item {
        padding-right: 50px !important;
        position: relative;
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
        &:before {
            display: block;
            content: "";
            position: absolute;
            top:0; 
            right:-800px;
            bottom:0;
            left:0;
            margin:auto;
            width: 12px;
            height: 2px;
            background-color: #40180A;
            transition: .2s;
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
        }
        &:after {
            display: block;
            content: "";
            position: absolute;
            top: 0; 
            right: -800px;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 12px;
            height: 2px; 
            background-color: #40180A;
            transition: .3s;
        }
        &.active {
            &:before {
                transform: rotate(0deg);
	            -webkit-transform: rotate(0deg);
            }
            &:after {
                background-color: transparent;
            }
        }
    }
}

@media (max-width: 767px) {
    .container {
        width: 100%;
        padding: 0 20px;
    }
    .qa {
        .qa-mainv {
            h1 {
                font-size: 30px;
            }
        }
        .qa-contents {
            margin-top: 57px;
            margin-bottom: 130px;
        }
        .qa-item {
            padding: 20px 0;
            p {
                padding-right: 25px;
            }
        }
        .q-item {
            padding-right: 25px!important;
            &:before {
                left: 94%;
                right: 0;
            }
            &:after {
                left: 94%;
                right: 0;
            }
        }
    }
}

@media (min-width: 768px) {
    .qa {
        .qa-mainv {
            margin: 0 360px;
            padding-top: 100px;
            h1 {
                font-size: 32px;
            }
        }
        .qa-contents {
            width: 860px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 130px;
            margin-top: 57px;
        }
        .qa-item {
            p {
                padding-right: 50px;
            }
        }
        .q-item {
            &:before {
                right: -800px;
            }
            &:after {
                right: -800px;
            }
        }
    }
}

