.feature {
    margin-bottom: 90px;
    
    .section-choose {
        .detail {
            font-size: 14px;
            display: block;
            text-align: center;
        }
    }
    .feature-wrap {
        width: 100%;
        margin-bottom: 70px;
        list-style: none;
        padding: 0;
        margin: 0;
        .feature-text {
            background-color: #F7F6F5;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            p {
                text-align: left;
                margin-top: 30px;
            }
        }
        .title-wrap {
            display: flex;
            h2 {
                margin-top: 0;
                letter-spacing: 0.08em;
                line-height: 1.75;
                font-weight: 400;
            }
            .num-box {
                background-color: #F46E64;
                color: #ffffff;
                padding: 3px 15px;
                text-align: center;
                line-height: 1.5;
            }
            .reason {
                font-size: 11px;
                color: #F46E64;
                display: block;
                padding-left: 3px;
            }
        }
    }
}

@media (max-width: 767px) {
    .feature.container {
        padding: 0;
        width: 100%;
    }
    .feature {
        .section-choose {
            height: 2400px;
            .detail {
                width: 90%;
                margin: 50px auto;
            }
        }
        .feature-wrap {
            display: block;
            width: 100%;
            height: 460px;
            margin: 60px 0 70px 0;
            list-style: none;
            padding: 0;
            position: relative;
            &:not(:first-of-type) {
                margin-top: 300px;
            }
            
            .feature-text {
                width: 100%;
                height: 460px;
                background-color: #F7F6F5;
                &:nth-child(odd) {
                    position: absolute;
                    top: 230px;
                }
                p {
                    text-align: left;
                    margin-top: 30px;
                    padding: 0 25px;
                }
            }
        }
        .title-wrap {
            display: flex;
            width: 86%;
            h2 {
                font-size: 20px;
                margin-top: 0;
                margin-left: 20px;
                letter-spacing: 0.08em;
                line-height: 1.75;
                font-weight: 400;
            }
            .num-box {
                background-color: #F46E64;
                color: #ffffff;
                padding: 3px 15px;
                font-size: 25px;
                text-align: center;
                line-height: 1.5;
            }
            .reason {
                font-size: 11px;
                color: #F46E64;
                display: block;
                padding-left: 3px;
            }
        }
        .feature-img {
            width: 100%;
            overflow: hidden;
            img {
                height: 230px;
                width: 100%;
            }
        }
    }
}

@media (min-width: 768px) {
    .feature {
        .mainv {
            h1 {
                font-size: 32px;
            }
        }
        .section-choose {
            .detail {
                width: 55%;
                margin: 65px auto 65px auto;
            }
        }
        .feature-wrap {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 460px;
            .feature-text {
                width: 50%;
                height: 460px;
                background-color: #F7F6F5;
                p {
                    text-align: left;
                    margin-top: 30px;
                    padding: 0 65px;
                }
            }
            .title-wrap {
                display: flex;
                width: 80%;
                h2 {
                    font-size: 24px;
                    margin-top: 0;
                    margin-left: 40px;
                    letter-spacing: 0.08em;
                    line-height: 1.75;
                    font-weight: 400;
                }
                .num-box {
                    background-color: #F46E64;
                    color: #ffffff;
                    padding: 3px 15px;
                    font-size: 32px;
                    text-align: center;
                    line-height: 1.5;
                }
                .reason {
                    font-size: 11px;
                    color: #F46E64;
                    display: block;
                    padding-left: 3px;
                }
            }
            .feature-img {
                width: 50%;
                img {
                    height: 460px;
                    width: 100%;
                }
            }
        }
    }
}