/*

Styling for form

*/

.c-form {
    font-size: 12px;
    line-height: (22/12);

    dl,
    dt,
    dd {
        margin: 0;
    }
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }

    label,
    input,
    textarea {
        display: block;
    }

    input,
    textarea,
    select {
        margin-top: 9px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: #f0f0f0;
        background-image: none;
        border: 1px solid #dddddd;
        border-radius: 2px;
        box-sizing: border-box;
        color: inherit;
        font-family: inherit;
        padding: 0.4em 0.8em;
        width: 100%;
        height: 30px;
        font-weight: 700;

        &:focus {
            outline: 1px #4d68a6 solid;
        }
    }

    select {
        background: #f0f0f0 url(../../common/img/page/icon_arrow_down.svg) no-repeat center right 15.5px/9px 9px;

        &::-ms-expand {
            display: none;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        white-space: nowrap;
        width: 1px;
        height: 1px;
        overflow: hidden;
        border: 0;
        padding: 0;
        clip: rect(0 0 0 0);
        // clip-path: inset(50%);
        margin: -1px;

        & + span {
            cursor: pointer;
            display: inline-block;
            padding: 0 0 0 30px;
            position: relative;

            &:before,
            &:after {
                content: "";
                display: block;
                left: 0;
                position: absolute;
            }

            &:before {
                -webkit-transform: translateY(-50%);
                background: #fff;
                border: 1px solid #d6d6d6;
                height: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
            }

            &:after {
                opacity: 0;
                top: 50%;
                transition: all 0.3s ease 0s;
            }
        }

        &:focus {
            & + span {
                &:before {
                    outline: 1px #4d68a6 solid;
                }
            }
        }

        &:checked {
            & + span {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    input[type="checkbox"] + span {
        &:before {
            border-radius: 2px;
        }

        &:after {
            -webkit-transform: translateY(-50%) rotate(-45deg);
            border-bottom: 3px solid #f43838;
            border-left: 3px solid #f43838;
            height: 12px;
            margin-top: -0.2em;
            transform: translateY(-50%) rotate(-45deg);
            width: 20px;
        }
    }

    input[type="radio"] + span {
        &:before {
            border-radius: 50%;
        }

        &:after {
            -webkit-transform: translateY(-50%);
            background: #f43838;
            border: 1px solid transparent;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            height: 8px;
            left: 6px;
            width: 8px;
        }
    }

    &--bluetext {
      color: blue;
    }

    .c-form--item {
        margin-top: 30px;
    }

    .c-form--item-submit {
        text-align: center;

        &--btn {
            max-width: 100%;
            width: 300px;
            height: 50px;
            background: #f47065;
            border-radius: 3px;
            font-weight: 700;
            font-size: 16px;
            display: block;
            margin: 0 auto;
            transition: border 0.3s, opacity 0.3s;
            position: relative;
            &::after{
                content: "";
                position: absolute;
                top: 50%;
                right: 35px;
                transform: translateY(-50%);
                display: block;
                width: 4.5px;
                height: 9px;
                background: url(../../common/img/page/icon_arrow_right.svg) center/contain no-repeat;
            }
            &:hover{
                opacity: .6;
            }
            input[type="submit"] {
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background: none;
                background-image: none;
                border: none;
                color: #fff;
                cursor: pointer;
                display: inline-block;
                padding: 0.6em 2em;
                text-decoration: none;
                width: 100%;
                height: 100%;
                margin: 0;

                &:disabled {
                    opacity: 0.6;
                }
            }
        }
    }

    .c-form--item--heading {
        p,
        label {
            color: inherit;
            font-size: 14px;
            font-weight: inherit;
            line-height: inherit;
            font-weight: 700;
        }

        &--required {
            color: #fff;
            display: inline-block;
            background: #d31d24;
            padding: 1px 7px;
            margin-left: 9px;
        }
    }

    .c-form--item--explanation {
        margin-top: 5px;
        font-weight: 700;
        color: #a3a3a3;
    }

    .c-form--item-notice {
        font-weight: 700;
        a {
            color: #4d68a6;
            transition: opacity 0.3s;

            @include pc {
                &:hover {
                    opacity: 0.6;
                }
            }

            &:focus {
                opacity: 0.6;
            }

            &:visited {
                color: #80006a;
            }
        }
    }

    /*contact form 7*/
    .wpcf7-not-valid-tip {
        margin: 5px 0;
    }

    span.wpcf7-list-item {
        display: block;
        margin: 20px 0;

        @include pc {
            margin: 10px 0;
        }

        &.first {
            margin-top: 0;
        }

        &.last {
            margin-bottom: 0;
        }
    }
}
