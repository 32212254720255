.u-all-p-md-10 {
    @include pc {
        padding: 10px !important;
    }
}

.u-all-p-sm-10 {
    @include tab {
        padding: 10px !important;
    }
}

@for $i from 0 through 40 {
    //5pxずつ増えていくようにする
    // top
    .u-pt-#{$i*5} {
        padding-top: 5px * $i !important;
    }

    .u-pt-md-#{$i*5} {
        @include pc {
            padding-top: 5px * $i !important;
        }
    }

    .u-pt-sm-#{$i*5} {
        @include tab {
            padding-top: 5px * $i !important;
        }
    }

    // left
    .u-pl-#{$i*5} {
        padding-left: 5px * $i !important;
    }

    .u-pl-md-#{$i*5} {
        @include pc {
            padding-left: 5px * $i !important;
        }
    }

    .u-pl-sm-#{$i*5} {
        @include tab {
            padding-left: 5px * $i !important;
        }
    }

    // bottom
    .u-pb-#{$i*5} {
        padding-bottom: 5px * $i !important;
    }

    .u-pb-md-#{$i*5} {
        @include pc {
            padding-bottom: 5px * $i !important;
        }
    }

    .u-pb-sm-#{$i*5} {
        @include tab {
            padding-bottom: 5px * $i !important;
        }
    }

    // right
    .u-pr-#{$i*5} {
        padding-right: 5px * $i !important;
    }

    .u-pr-md-#{$i*5} {
        @include pc {
            padding-right: 5px * $i !important;
        }
    }

    .u-pr-sm-#{$i*5} {
        @include tab {
            padding-right: 5px * $i !important;
        }
    }
}
