@for $i from 0 through 40 {
    //5pxずつ増えていくようにする
    // top
    .u-mt-md-#{$i*5} {
        @include pc {
            margin-top: 5px * $i !important;
        }
    }

    .u-mt-sm-#{$i*5} {
        @include tab {
            margin-top: 5px * $i !important;
        }
    }

    .u-mt-#{$i*5} {
        margin-top: 5px * $i !important;
    }

    // left
    .u-ml-md-#{$i*5} {
        @include pc {
            margin-left: 5px * $i !important;
        }
    }

    .u-ml-sm-#{$i*5} {
        @include tab {
            margin-left: 5px * $i !important;
        }
    }

    .u-ml-#{$i*5} {
        margin-left: 5px * $i !important;
    }

    // bottom
    .u-mb-md-#{$i*5} {
        @include pc {
            margin-bottom: 5px * $i !important;
        }
    }

    .u-mb-sm-#{$i*5} {
        @include tab {
            margin-bottom: 5px * $i !important;
        }
    }

    .u-mb-#{$i*5} {
        margin-bottom: 5px * $i !important;
    }

    // right
    .u-mr-md-#{$i*5} {
        @include pc {
            margin-right: 5px * $i !important;
        }
    }

    .u-mr-sm-#{$i*5} {
        @include tab {
            margin-right: 5px * $i !important;
        }
    }

    .u-mr-#{$i*5} {
        margin-right: 5px * $i !important;
    }
}
