.case-study {
    .tax-wrap {
        width: 100%;
        margin-top: 0;
        .tax-list {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
        }
        .tax-item {
            background-color: #EADCCF;
            text-align: center;
            height: 50px;
            line-height: 2.8;
        }
        .tax-button {
            font-size: 18px;
            color: #8B7850;
            text-decoration: none;
            display: block;
            >span {
                position: relative;
                &:before {
                    background: center/8px 8px no-repeat url(../../common/img/taxo-arrow.png);
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    }
            }
            &.-active {
                background-color: #8B7850;
                color: #ffffff;
            }
        }
    }
    .archive-main {
        margin-top: 60px;
        margin-bottom: 80px;
        .container {
            .archive-list {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
                justify-content: flex-start;
            }
        }
    }
    .archive-item-wrap {
        width: 23%;
        margin-bottom: 40px;

        .archive-item-img {
            margin: 0;
            img {
                width: 100%;
                height: 180px;
                object-fit: cover;
            }
        }
        .archive-item-area {
            text-align: center;
            font-size: 16px;
            font-weight: normal;
            margin-top: 15px;
            color: #3E3E3E;
            line-height: 1.37;
        }
        .archive-title {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            width: 100%;
            line-height: 1.37;
            text-align: left;
        }
        .archive-item-link {
            text-decoration: none;
            color: #3E3E3E;
            display: block;
        }
        .archive-item-more {
            font-size: 12px;
            color: #8B7850;
            display: block;
            text-align: right;
            margin-top: 12px;
            letter-spacing: 0.08em;
            
            svg {
                vertical-align: text-top;
            }
            span {
                padding-right: 5px;
                font-weight: bold;
            }
        }
    }
    .single-head {
        text-align: center;
        span {
            font-size: 16px;
            font-weight: normal;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -10px;
                display: inline-block;
                width: 180px;
                height: 1px; 
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translate(-50%); 
                background-color: #383838; 
            }
        }
        .archive-title {
            padding: 20px 0;
            font-size: 18px;
            line-height: 1.3;
        }
    }
    .single-main {
        margin-top: 55px;
    }
    .swiper-group {
        position: relative;
    }
    .swiper-slide {
        text-align: center;
    }
    .swiper-button-prev, .swiper-button-next {
        display: inline-block;
        perspective: 300px;
        position: absolute;
        height: 36px;
        width: 36px;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.7;
        &:hover {
            opacity: 0.6;
        }
        &:focus {
            outline: none;
        }
    }
    .swiper-button-prev {
        background: url(../../common/img/swiper-arrow.png) no-repeat center center / contain;
        &:after {
            content: "";
        }
    }
    .swiper-button-next {
        background: url(../../common/img/swiper-arrow-next.png) no-repeat center center / contain;
        &:after {
            content: "";
        }
    }
    .data-wrap {
        margin-top: 55px;
        h2 {
            font-size: 20px;
            padding-bottom: 20px;
            font-weight: normal;
        }
        .data-table {
            border-collapse: collapse;
            border-spacing: 0;
            box-sizing: border-box;
            width: 100%;
            tr {
                font-weight: normal;
                &:last-child {
                    th,td {
                        border-bottom: solid 1px #9d9d9d;
                    }
                }
            }
            th,td {
                background: #F9F4F0;
                border-color: #9D9D9D;
                font-size: 18px;
                line-height: 1.7;
                padding: 14px 19px;
                text-align: left;
                border-top: solid 1px #9D9D9D;
                font-weight: normal;
            }
            tr {
                &:nth-child(even) {
                    th,td {
                        background: #fff;
                    }
                }
            }
        }
    }
    .button-wrap {
        text-align: center;
        padding: 60px 0;
        margin: 0 auto;
    }
    .deco-button-frame {
        display: block;
        margin: 0 auto;
        position: relative;
        &::before {
            position: absolute;
            bottom: 10px;
            right: 10px;
            border-width: 1px;
            content: "";
            border-color: #343434;
            box-sizing: border-box;
            border-style: solid;
            width: 100%;
            height: 100%;
            z-index: 5;
        }
        &::after {
            background: center/29px 5px no-repeat url(../../common/img/button-frame-arrow.svg);
            content: "";
            position: absolute;
            top: 30%;
            width: 29px;
            height: 5px;
            right: -12px;
        }
    }
    .frame-deco-button {
        display: inline-block;
        -webkit-perspective: 300px;
        perspective: 300px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        transition: all .3s;
        box-sizing: border-box;
        overflow: hidden;
        border: 1px solid #8B7850;
        color: #8B7850;
        background-color: #ffffff;
        text-align: center;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .case-study.container {
        width: 100%;
        padding: 0;
        overflow-x: hidden;
    }
    .case-study {
        .tax-wrap {
            .tax-list {
                display: block;
            }
            .tax-item {
                width: 100%;
            }
            .tax-button {
                font-size: 18px;
                >span {
                    position: relative;
                    &:before {
                        top: 0;
                        left: 120px;
                        width: 100%;
                        }
                }
                &.-active {
                    background-color: #8B7850;
                    color: #ffffff;
                }
            }
        }
        .swiper-slide {
            text-align: center;
            img {
                width: 100%;
                height: 250px;
                object-fit: cover;
            }
        }
        .archive-main {
            .container {
                padding: 0 20px;
            }
        }
        .contents {
            margin: 20px 30px;
        }
        .swiper-button-prev {
            left: -5%;
        }
        .swiper-button-next {
            right: -5%;
        }
        .archive-list {
            margin: 60px 20px;
            margin-bottom: 20px;
        }
        .archive-item-wrap {
            width: 100%;
            margin-bottom: 40px;
    
            .archive-item-img {
                margin: 0;
                img {
                    width: 100%;
                }
            }
            .archive-item-area {
                text-align: center;
                font-size: 16px;
                font-weight: normal;
                margin-top: 15px;
                color: #3E3E3E;
            }
            .archive-title {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 100%;
            }
            .archive-item-more {
                font-size: 12px;
                color: #8B7850;
                text-decoration: none;
                display: block;
                text-align: right;
                margin-top: 12px;
                svg {
                    vertical-align: text-top;
                }
                span {
                    padding-right: 5px;
                    font-weight: bold;
                }
            }
        }
        .button-wrap {
            width: 55%;
            .button {
                background-color: #8B7850;
                color: #ffffff;
            }
        }
    }
}

@media (min-width: 768px) {
    .case-study {
        .data-table th {
            width: 30%;
        }
        .button-wrap {
            width: 13%;
            .deco-button-frame {
                width: 94%;
                &:hover {
                    .frame-deco-button {
                        color: #ffffff;
                    &:after {
                        top: 0;
                        left: 0;
                        background-color: #8B7850;
                    }
                    }
                }
            }
            .frame-deco-button {
                width: 100%;
                cursor: pointer;
                &:before, &:after {
                    transition: all .3s;
                    box-sizing: border-box;
                    position: absolute;
                    z-index: -1;
                    display: block;
                    content: '';
                }
                &:after {
                    top: -100%;
                    left: -100%;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .swiper-slide {
            text-align: center;
            img {
                width: 700px;
                height: 470px;
                object-fit: cover;
            }
        }
        .archive-main {
            .container {
                padding: 0 50px;
            }
        }
        .archive-item-wrap {
            margin-right: 2.6%;
            &:nth-of-type(4n) {
                margin-right: 0;
            }
        }
        .archive-item-link {
            &:hover {
                opacity: 0.6;
            }
        }
        .tax-wrap {
            .tax-list {
                display: flex;
            }
            .tax-item {
                width: 25%;
                height: 50px;
                line-height: 2.8;
                &:not(:last-of-type) {
                    margin-right: 1px;
                }
            }
            .tax-button {
                font-size: 18px;
                >span {
                    position: relative;
                    &:before {
                        background: center/8px 8px no-repeat url(../../common/img/taxo-arrow.png);
                        content: '';
                        position: absolute;
                        height: 100%;
                        top: 0;
                        left: 120px;
                        width: 100%;
                        }
                }
                &:hover {
                    background-color: #8B7850;
                    color: #ffffff;
                }
                &.-active {
                    background-color: #8B7850;
                    color: #ffffff;
                }
            }
        }
        .contents {
            margin: 90px 288px 0 288px;
        }
        .swiper-button-prev {
            left: -10%;
        }
        .swiper-button-next {
            right: -10%;
        }
    }
}