h1,h2,h3,h4,p {
    margin: 0;
}

body{
    margin: 0;
    font-family: 'Open Sans','Futura','Avenir Next','Yu Gothic', '游ゴシック', 'YuGothic', '游ゴシック体', sans-serif;
}
table {
    font-family: "Yu Gothic", "游ゴシック", "YuGothic", "游ゴシック体","ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif;
}

.container {
    box-sizing: border-box;
}

.swiper-container-horizontal {
    > .swiper-pagination-bullets {
        bottom: 20px !important;
        .swiper-pagination-bullet {
            margin: 0 10px !important;
        }
    }
}


// ヘッダー
.header {
    border-top: 8px solid #EADCCF;
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
    .header-innerwrap {
        display: flex;
        align-items: center;
        .logo-wrap {
            display: flex;
            flex-direction: column;
            .c-detail {
                font-size: 10px;
                padding-top: 5px;
            }
        }
    }
    
    .nav-wrap {
        display: flex;
        width: 75%;
        justify-content: space-between;
        align-items: center;
    }
    .contact-btn-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .contact-button {
            color: #3E3E3E;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 190px;
            height: 42px;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            line-height: 42px;
            outline: none;
            background-color: #ffffff;
            border: 1px solid #8B7850;
            -webkit-perspective: 300px;
            perspective: 300px;
            text-decoration: none;
            transition: all .3s;
            box-sizing: border-box;
            overflow: hidden;
            position: relative;
            
            &:first-child {
                margin-bottom: 15px;
            }
            .contact, .document {
                padding-left: 5px;
                position: relative;
                &:hover {
                    &::before {
                        background: center/17px 12px no-repeat url(../../common/img/header-mail-white.png);     
                    } 
                }
                &::before {
                    left: -20%;
                    top: 36%;
                    content: '';
                    position: absolute;
                    width: 17px;
                    height: 12px;
                    background: center/17px 12px no-repeat url(../../common/img/header-mail.png); 
                }
                &.-doc {
                    &:hover {
                        &::before {
                            background: center/17px 12px no-repeat url(../../common/img/header-catalog-white.png); 
                        }
                    }
                    &::before {
                        left: -20%;
                        top: 36%;
                        content: '';
                        position: absolute;
                        width: 17px;
                        height: 12px;
                        background: center/17px 12px no-repeat url(../../common/img/header-catalog.png);  
                    }
                }
            }
        }
    }
    .pc-menu {
        width: 80%;
    }
    .menu {
        list-style: none;
        padding-left: 0;
        a {
            font-size: 16px;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

// フッター
.footer {
    .footer-banner-list {
        background-color: #F9F4F0;
        .footer-banner-link {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            text-decoration: none;
            height: 150px;
            background-color: #E2D4C8;
            -webkit-perspective: 300px;
            perspective: 300px;
            transition: all .3s;
            box-sizing: border-box;
            overflow: hidden;
            border: 1px solid #E2D4C8;
            }
        .footer-banner-item {
            background-color: #E2D4C8;
            margin-right: 1px;
            &:last-of-type {
                margin-right: 0;
            }
            h2 {
                font-size: 16px;
                font-weight: normal;
                margin-top: 10px;
                line-height: 1.37;
            }
            span {
                font-size: 22px;
                font-weight: bold;
                line-height: 1.36;
            }
        }
    }
    .footer-lower {
        position: relative;
        .footer-meta {
            color: #8B7850;
            font-size: 16px;
            font-weight: normal;
            address {
                font-style: normal;
                a {
                    color: #8B7850;
                    text-decoration:none;
                }
            }

            .footer-meta-logo {
                display: block;
            }

            > a {
                text-decoration: none;
                color: #5e5c5c;
                display: block;
            }
            .company-name {
                color: #5e5c5c;
                font-weight: 600;
                margin-top: 5px;
                display: block;
            }
        }
        .address-wrap {
            margin-top: 15px;
            line-height: 1.6;
        }
        .footer-anchor {
            position: absolute;
            top: 0;
            right: 4%;
        }
    }
    .menu {
        list-style: none;
        li {
            padding: 15px 0;
            line-height: 1.35;
            &:hover {
                opacity: 0.6;
            }
            a {
                text-decoration: none;
                color: #8B7850;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .copyright {
        height: 100px;
        background-color: #8B7850;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
    }
}

// ページャ
.pager {
    text-align: center;
    span {
        display: inline-block;
        font-family: 'Avenir Next';
        background-color: #8B7850;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        color: #ffffff;
        font-size: 14px;
        line-height: 2;
        margin: 0 6px;
    }
    a {
        color: #3E3E3E;
        text-decoration: none;
    }
    a.page-numbers {
        font-size: 14px;
        width: 26px;
        height: 26px;
        display: inline-block;
        margin: 0 6px;
        line-height: 2;
        &:hover {
            display: inline-block;
            font-family: 'Avenir Next';
            background-color: #8B7850;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            color: #ffffff;
            font-size: 14px;
            line-height: 2;
        }
    }
    .page-numbers.next {
        color: #ffffff;
        position: relative;
        &:hover {
            color: #8B7850;
            &:before {
                height: 100%;
                top: 0;
                left: 0;
                width: 100%;
                content: '';
                position: absolute;
                background: center/13px 5px no-repeat url(../../common/img/page-arrow-white.png);  
            }
        }
        &::before {
            background: center/13px 5px no-repeat url(../../common/img/page-arrow.png);
            content: '';
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
    .page-numbers.prev {
        color: #ffffff;
        position: relative;
        &:hover {
            color: #8B7850;
            &:before {
                height: 100%;
                top: 0;
                left: 0;
                width: 100%;
                content: '';
                position: absolute;
                background: center/13px 5px no-repeat url(../../common/img/page-arrow-white-left.png);  
            }
        }
        &::before {
            background: center/13px 5px no-repeat url(../../common/img/page-arrow-left.png);
            content: '';
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}

// ヘッダー画像とテキスト
.mainv {
    width: 100%;
    position: relative;
    background: url(/wp-content/themes/seiryo/common/img/company.png) no-repeat center;
    background-size: cover;
    padding: 132px 0;
    span {
        display: block;
        color: #F25043;
        font-size: 14px;
        text-align: center;
        position: relative;
        letter-spacing: 0.08em;
        line-height: 1.35;
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -28px;
            display: inline-block;
            width: 180px;
            height: 1px; 
            -moz-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translate(-50%); 
            background-color: #383838; 
        }
    }
    h1 {
        color: #3E3E3E;
        text-align: center;
        margin-top: 64px;
        letter-spacing: 0.08em;
        line-height: 1.35;
        font-weight: normal;
    }
}

.single-footer {
    padding-top: 10px;
    a {
        text-decoration: none;
        color: #3E3E3E;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &:hover {
            opacity: 0.6;
        }
        span {
            margin-left: 8px;
        }
    }
}


@media (max-width: 767px){
    .header {
        padding: 16px 20px;
        .header-innerwrap {
            position: relative;
            height: 85%;
            .logo-wrap {
                .logo {
                    width: 200px;
                    img {
                        width: 150px;
                    }
                }
            }
            .pc-menu, .contact-btn-wrap {
                display: none;
            }
            .sp-menu {
                .menu {
                    margin-top: 0;
                }
            }
            
            .menu-trigger {
                width: 50px;
                height: 50px;
                z-index: 11;
                background: #8B7850;
                position: absolute;
                right: 0;
                top: 0;
                display: inline-block;
                transition: all .4s;
                box-sizing: border-box;
                cursor: pointer;
                span {
                    position: absolute;
                    background: #fff;
                    width: 30px;
                    height: 2px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    display: inline-block;
                    transition: all .4s;
                    box-sizing: border-box;
                    cursor: pointer;
                    &:nth-of-type(1) {
                        top: 15px;
                    }
                    &:nth-of-type(2) {
                        top: 0;
                        bottom: 0;
                    }
                    &:nth-of-type(3) {
                        bottom: 15px;
                    }
                }
                &.active {
                    span {
                        &:nth-of-type(1) {
                            transform: translateY(9px) rotate(-45deg);
                        }
                        &:nth-of-type(2) {
                            opacity: 0;
                        }
                        &:nth-of-type(3) {
                            transform: translateY(-9px) rotate(45deg);
                        }
                    }
                }
            }
        }
        .sp-menu {
            background: #8B7850;
            left: 0;
            overflow: scroll;
            top: 129px;
            z-index: 999;
            position: fixed;
            width: 100%;
            height: 100%;
            text-align: left;
            .menu-item {
                border-bottom: 1px solid #fff;
                &:last-of-type {
                    margin-bottom: 80px;
                }
                a {
                    color: #ffffff;
                    padding: 24px 16px;
                    display: block;
                }
            }
        }
    }

    .mainv {
        h1 {
            font-size: 30px;
        }
    }

    .footer {
        .footer-lower {
            .f-container {
                margin: 65px 0px;
            }
            .footer-meta {
                > a {
                    width: 55%;
                    margin-left: 50px;
                }
                .footer-meta-logo {
                    width: 150px;
                }
                .company-name {
                    font-size: 18px;
                }
            }
        }
        .menu {
            padding-left: 70px;
        }
        .footer-main {
            display: block;
            
        }
        .address-wrap {
            margin: 25px 35px 25px 50px;
            .footer-address {
                &:last-child {
                    margin-top: 20px;
                }
            }
        }
        .copyright {
            padding: 0 20px;
            font-size: 12px;
        }
    }
}

@media (min-width: 768px) {
    body {
        width: 100%;
        min-width: 1280px;
    }

    .header {
        padding: 0 20px;
        .header-innerwrap {
            height: 140px;
        }
        .logo-wrap {
            width: 25%;
            img {
                width: 180px;
            }
        }
        .menu {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-top: 0;
            
            a {
                display: block;
                height: 40px;
                line-height: 40px;
                padding: 20px;
                color: #3E3E3E;
                &:hover {
                    background-color: #E2D4C8;
                    color: #ffffff;
                }
            }
        }

        .contact-button {
            &:before, &:after {
                transition: all .3s;
                box-sizing: border-box;
                position: absolute;
                z-index: -1;
                display: block;
                content: '';
            }
            &:hover {
                color: #fff;
                &:after {
                    top: 0;
                    left: 0;
                    background-color: #8B7850;
                }
            }
            &:after {
                top: -100%;
                left: -100%;
                width: 100%;
                height: 100%;
            }
        }
    }
    .mainv {
        h1 {
            font-size: 32px;
        }
    }
    .footer {
        .footer-banner-list {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .footer-banner-link {
            width: 265px;
            cursor: pointer;
            &:before, &:after {
                transition: all .3s;
                box-sizing: border-box;
                position: absolute;
                z-index: -1;
                display: block;
                content: '';
            }
            &:hover {
                color: #E2D4C8;
                &:after {
                    top: 0;
                    left: 0;
                    background-color: #F9F4F0;
                }
            }
            &:after {
                top: -100%;
                left: -100%;
                width: 100%;
                height: 100%;
            }
        }
        .menu {
            border-left: 1px solid #8B7850;
            padding-left: 125px;
        }
        .copyright {
            font-size: 14px;
        }
    }
    .footer-lower {
        .f-container {
            margin: 65px 110px;
        }
        .footer-meta-logo {
            width: 180px;
            a {
                width: 200px;
            }
        }
        .company-name {
            font-size: 22px;
        }
    }
    .footer-main {
        display: flex;
        justify-content: space-between;
        .address-wrap {
            display: flex;
            justify-content: space-between;
            width: 55%;
        }
        .footer-navwrap {
            width: 35%;
        }
    }
}



// underline付きspan
.front-feature, .title-wrap, .front-news{
    >span {
        display: block;
        color: #F25043;
        font-size: 14px;
        text-align: center;
        position: relative;
        letter-spacing: 0.08em;
        line-height: 1.35;
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -28px;
            display: inline-block;
            width: 180px;
            height: 1px; 
            -moz-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translate(-50%); 
            background-color: #383838; 
        }
    }
    h2 {
        font-size: 22px;
        font-weight: bold;
        margin-top: 52px;
    }
}

// ハンバーガーメニュー開いている時に背景固定
html.fixed,
html.fixed body {
  @media all and (max-width: 767px) {
    height: 100%;
    overflow: hidden;
  }
}
