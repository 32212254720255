.front {
    margin-bottom: 90px;
    .swiper-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .maintext-wrap {
            width: 100%;
            text-align: center;
            position: absolute;
            z-index: 10;
            h1 {
                color: #ffffff;
                letter-spacing: 0.08em;
                line-height: 1.35;
                font-weight: normal;

                @include sp {
                    font-size: 26px;
                }
            }
            p {
                margin: 15px auto;
                width: 70%;
                color: #ffffff;
            }
        }
        .maintext{
            &--btn-wrap{
                margin: 20px auto 0;
                width: 306px;
                max-width: 100%;
            }
        }
    }
    .swiper-slide {
        
        &::before{
            content: ' ';
            background-color: rgba(0,0,0,.5);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 4px;
            left: 0;
           }
    }
    .front-feature {
        margin-top: 60px;
        text-align: center;
        >p {
            margin: 0 auto 50px auto;
            font-size: 14px;
        }
    }
    .feature-wrap {
        .circle-wrap {
            display: flex;
            flex-direction: column;
            text-align: center;
            >h3 {
                font-size: 18px;
                letter-spacing: 0.08em;
                line-height: 1.35;
                margin-top: 40px;
                margin-bottom: 20px;
            }
            img {
                width: 225px;
                margin: 0 auto;
            }
        }
    }
    .button-wrap {
        text-align: center;
        padding: 60px 0;
        position: relative;
    }

    // 右下装飾ボタン
    .deco-button {
        display: block;
        width: 330px;
        margin: 0 auto;
        position: relative;
        &.-red {
            margin: 0;
            &::after {
                position: absolute;
                bottom: -5px;
                right: -10px;
                border-width: 0 1px 1px 0;
                content: "";
                border-color: #ffffff;
                box-sizing: border-box;
                border-style: solid;
                width: 110px;
                height: 40px;
            }
        }
        &::after {
            position: absolute;
            bottom: -5px;
            right: -5px;
            border-width: 0 1px 1px 0;
            content: "";
            border-color: #8B7850;
            box-sizing: border-box;
            border-style: solid;
            width: 110px;
            height: 40px;
        }
    }
    
    .deco-button-frame {
        display: block;
        margin: 0 auto;
        position: relative;
        &::before {
            position: absolute;
            bottom: 10px;
            right: 10px;
            border-width: 1px;
            content: "";
            border-color: #343434;
            box-sizing: border-box;
            border-style: solid;
            width: 100%;
            height: 100%;
            z-index: 5;
        }
        &::after {
            background: center/29px 5px no-repeat url(../../common/img/button-frame-arrow.svg);
            content: "";
            position: absolute;
            top: 30%;
            width: 29px;
            height: 5px;
            right: -12px;
        }

        &.-no-deco{
            &::before, &::after{
                display: none;
            }
        }
    }
    .frame-deco-button {
        display: inline-block;
        -webkit-perspective: 300px;
        perspective: 300px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        transition: all .3s;
        box-sizing: border-box;
        overflow: hidden;
        border: 1px solid #F25043;
        color: #ffffff;
        background-color: #F25043;
        text-align: center;

        &.-color-light{
            background-color: #F47065;
        }
    }

    .button {
        display: inline-block;
        -webkit-perspective: 300px;
        perspective: 300px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        transition: all .3s;
        box-sizing: border-box;
        overflow: hidden;
        border: 1px solid #8B7850;
        color: #8B7850;
        background-color: #ffffff;
        &.-red {
            border: 1px solid #ffffff;
            color: #ffffff;
            background-color: transparent;
            text-align: center;
        }
        span {
            font-size: 14px;
        }
    }
    .front-concept {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;
        .textbox {
            background-color: #F25043;
            color: #ffffff;
            height: auto;
            opacity: 0.9;
            position: absolute;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            letter-spacing: 0.08em;
            line-height: 1.35;
            h2 {
                font-weight: normal;
            }
            h3 {
                font-weight: normal;
                overflow: hidden;
                text-align: left;
                span {
                    display: inline-block;
                    padding: 0 0.5em 0 0;
                    position: relative;
                    &:before, &:after {
                        border-top: 1px solid;
                        content: "";
                        position: absolute;
                        top: 50%;
                        width: 99em;
                    }
                    &:before,&:after {
                        border-top: 1px solid;
                        content: "";
                        position: absolute;
                        top: 50%;
                        width: 99em;
                    }
                    &:before {
                        right: 100%;
                    }
                    &:after {
                        left: 100%;
                    }
                }
            }
            p {
                font-size: 16px;
            }
        }
    }
    .front-casestudy {
        margin-top: 100px;
        text-align: center;
        .title-wrap {
            background-color: #F9F4F0;
            p {
                margin-top: 20px;
                line-height: 1.35;
            }
        }
        .archive-item-link {
            text-decoration: none;
            color: #3E3E3E;
            display: block;
        }
    }
    .front-contact {
        border-top: 8px solid #F9F4F0;
        border-bottom: 8px solid #F9F4F0;
        .left-wrap {
            h2 {
                font-family: 'Avenir Next';
                font-weight: bold;
            }
            span {
                display: block;
                font-size: 18px;
                font-weight: bold;
                margin-top: -15px;
                letter-spacing: 0.35em;
            }
            p {
                margin-top: 35px;
                font-size: 20px;
                font-weight: bold;
            }
        }
        .right-wrap {
            display: flex;
            align-items: center;
            flex-direction: column;
            img {
                width: 38px;
                height: 50px;
            }
            .phone-wrap {
                display: flex;
                align-items: center;
                margin-top: 30px;
            }

        }
        .phone-num {
            margin-left: 7px;
            span {
                font-size: 14px;
                font-weight: bold;
            }
            h3 {
                font-weight: bold;
            }
        }
    }
    .front-news {
        text-align: center;
        margin-top: 60px;
        p {
            margin-top: 20px;
            line-height: 1.35;
        }
    }

    // トップの施工事例
    .archive-list {
        margin-bottom: 20px;
    }
    .archive-item-wrap {
        margin-bottom: 40px;

        .archive-item-img {
            margin: 0;
            img {
                width: 100%;
            }
        }
        .archive-item-area {
            text-align: center;
            font-size: 16px;
            font-weight: normal;
            margin-top: 15px;
            color: #3E3E3E;
        }
        .archive-title {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            width: 100%;
            text-align: left;
        }
        .archive-item-more {
            font-size: 12px;
            color: #8B7850;
            display: block;
            text-align: right;
            margin-top: 12px;
            svg {
                vertical-align: text-top;
            }
            span {
                padding-right: 5px;
                font-weight: bold;
            }
        }
    }

    // トップのお知らせ
    .news-list {
        margin-bottom: 90px;
    }
    .news-item-wrap {
        padding: 30px 0;
        border-bottom: 1.5px solid #D6D6D6;
        width: 100%;

        .cat-list {
            margin: 0 15px;
            padding: 0;
            list-style: none;
            text-align: left;
            a {
                border-radius: 16px;
                text-decoration: none;
                color: #ffffff;
            }
        }
        .news-item-title {
            font-size: 16px;
            line-height: 1.3;
            text-align: left;
        }
        .news-item-text {
            padding-top: 35px;
            letter-spacing: 0.08em;
            line-height: 1.25;
        }
        .news-item-link {
            display: flex;
            color: #343434;
            text-decoration: none;
            align-items: center;
            justify-content: flex-end;
            padding-top: 10px;
            &:hover {
                opacity: 0.6;
            }
            span {
                margin-left: 8px;
            }
        }
        .news-thumbnail-wrap {
            display: flex;
            margin-top: 25px;
            img {
                margin-right: 20px;
            }
            div {
                padding-top: 0;
            }
        }
    }
}


@media (max-width: 767px) {
    .front.container {
        width: 100%;
        padding: 0;
    }
    .front {
        .main
        .front-feature {
            >p {
                width: 80%;
                margin: 20px auto 50px auto;
            }
        }
        .feature-wrap {
            margin: 0 20px;
            display: block;
            .circle-wrap {
                margin-bottom: 50px;
                >h3 {
                    font-size: 18px;
                    letter-spacing: 0.08em;
                    line-height: 1.35;
                    margin-top: 40px;
                }
                img {
                    width: 225px;
                    margin: 0 auto;
                }
            }
        }
        .front-casestudy {
            .title-wrap {
                padding: 57px 20px;
            }
        }
        .button-wrap {
            &.-contact {
                width: 100%;
            }
        }
        .deco-button {
            width: 65%;
            .button {
                font-size: 14px;
                width: 95%;
                bottom: 28%;
                left: 49%;
                background-color: #8B7850;
                border: 1px solid #8B7850;
                color: #ffffff;
                &.-red {
                    background-color: transparent;
                    border: 1px solid #ffffff;
                    color: #ffffff;
                    
                    &::after {
                        right: 0;
                    }
                }
            }
        }
        .deco-button-frame {
            width: 75%;
            .frame-deco-button {
                width: 100%;
            }
        }

        .front-concept {
            margin-bottom: 460px;
            img {
                width: 100%;
                height: 230px !important;
                object-fit: cover;
            }
            .baseimg {
                display: none;
            }
            .textbox {
                left: 10%;
                top: 50%;
                padding: 20px 20px 50px 20px;
                >span {
                    font-size: 13px;
                }
                h2 {
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                h3 {
                    font-size: 20px;
                }
                p {
                    margin-top: 20px;
                    margin-bottom: 30px;
                }
            }
        }
        .front-contact {
            display: block;
            padding-top: 30px;
            .left-wrap {
                padding: 0 20px;
                h2 {
                    font-family: 'Avenir Next';
                    font-size: 63px;
                    font-weight: bold;
                }
                span {
                    display: block;
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: -15px;
                    letter-spacing: 0.35em;
                }
                p {
                    margin-top: 35px;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
            .right-wrap {
                img {
                    width: 38px;
                    height: 50px;
                }
                .phone-wrap {
                    display: flex;
                    align-items: center;
                    margin-top: 30px;
                }
    
            }
            .phone-num {
                margin-left: 7px;
                span {
                    font-size: 14px;
                    font-weight: bold;
                }
                h3 {
                    font-size: 33px;
                    font-weight: bold;
                    a {
                        color: #3E3E3E;
                        text-decoration: none;
                    }
                }
            }
        }
        .archive-list {
            margin: 60px 30px;
            margin-bottom: 20px;
        }
        .archive-item-wrap {
            width: 100%;
            margin-bottom: 40px;
    
            .archive-item-img {
                margin: 0;
                img {
                    width: 100%;
                    height: 180px;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }
            .archive-item-area {
                text-align: center;
                font-size: 16px;
                font-weight: normal;
                margin-top: 15px;
                color: #3E3E3E;
            }
            .archive-title {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 100%;
            }
            .archive-item-more {
                font-size: 12px;
                color: #8B7850;
                display: block;
                text-align: right;
                margin-top: 12px;
                svg {
                    vertical-align: text-top;
                }
                span {
                    padding-right: 5px;
                    font-weight: bold;
                }
            }
        }
        .front-news {
            p {
                padding: 0 20px;
            }
        }
        .news-list {
            padding: 0 20px;
        }
        .news-item-wrap {
            display: block;
            width: 100%;
            .news-item-header {
                text-align: left;
            }
            .cat-list {
                margin: 10px 0;
                a {
                    font-size: 11px;
                    padding: 4px 24px;
                }
            }
            .news-item-title {
                font-size: 16px;
                text-align: left;
            }
            .news-item-text {
                padding-top: 35px;
                letter-spacing: 0.08em;
                line-height: 1.25;
            }
            .news-item-link {
                width: 100%;
            }
        }
    }
}





@media (min-width: 768px) {
    .front {
        .swiper-slide {
            img {
                width: 100%;
            }
        }
        .front-mainv {
            .maintext-wrap {
                top: 16%;
            }
            h1 {
                font-size: 32px;
            }
        }
        .front-feature {
            >p {
                width: 56%;
                margin: 30px auto 50px auto;
            }
        }
        .feature-wrap {
            margin: 0 105px;
            display: flex;
            justify-content: space-around;
            .circle-wrap {
                margin: 0 20px;
                >h3 {
                    font-size: 18px;
                    letter-spacing: 0.08em;
                    line-height: 1.35;
                    margin: 40px 0;
                }
                img {
                    width: 225px;
                    margin: 0 auto;
                }
            }
        }
        .front-casestudy {
            .title-wrap {
                padding: 57px 0;
            }
        }
        .button-wrap {
            &.-contact {
                width: 540px;
                padding: 35px 0;
            }
        }
        .button {
            width: 95%;
            cursor: pointer;
            &:before, &:after {
                transition: all .3s;
                box-sizing: border-box;
                position: absolute;
                z-index: -1;
                display: block;
                content: '';
            }
            &:hover {
                color: #fff;
                &:after {
                    top: 0;
                    left: 0;
                    background-color: #8B7850;
                }
            }
            &:after {
                top: -100%;
                left: -100%;
                width: 100%;
                height: 100%;
            }
            
            &.-red {
                width: 100%;
                &:hover {
                    color: #F25043;
                    &:after {
                        top: 0;
                        left: 0;
                        background-color: #ffffff;
                    }
                }
            }
        }

        // フレーム付きボタン
        .deco-button-frame {
            width: 94%;
            &:hover {
                .frame-deco-button {
                    color: #F25043;
                &:after {
                    top: 0;
                    left: 0;
                    background-color: #ffffff;
                }
                }
            }
        }
        .frame-deco-button {
            width: 100%;
            cursor: pointer;
            &:before, &:after {
                transition: all .3s;
                box-sizing: border-box;
                position: absolute;
                z-index: -1;
                display: block;
                content: '';
            }
            &:after {
                top: -100%;
                left: -100%;
                width: 100%;
                height: 100%;
            }
        }

        .front-concept {
            .textbox {
                width: 630px;
                left: 100px;
                top: -40px;
                padding: 40px 50px 80px 50px;
                h2 {
                    font-size: 24px;
                    margin-bottom: 40px;
                }
                h3 {
                    font-size: 30px;
                }
                p {
                    margin-top: 25px;
                    margin-bottom: 40px;
                    font-size: 16px;
                    line-height: 1.375;
                }
            }
            .baseimg {
                height: 80%;
                top: 88px;
                position: relative;
                z-index: -1;
            }
        }
        .front-contact {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 340px;
            .left-wrap {
                h2 {
                    font-size: 63px;
                }
                span {
                    display: block;
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: -15px;
                    letter-spacing: 0.35em;
                }
                p {
                    margin-top: 35px;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
            .right-wrap {
                display: flex;
                width: 50%;
                align-items: center;
                flex-direction: column;
                img {
                    width: 38px;
                    height: 50px;
                }
                .phone-wrap {
                    display: flex;
                    align-items: center;
                    margin-top: 30px;
                }
    
            }
            .phone-num {
                margin-left: 7px;
                span {
                    font-size: 14px;
                    font-weight: bold;
                }
                h3 {
                    font-size: 42px;
                    font-weight: bold;
                }
            }
        }
        .archive-list {
            margin: 60px 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .archive-item-wrap {
            width: 23%;
            margin-bottom: 40px;
    
            .archive-item-img {
                margin: 0;
                img {
                    width: 100%;
                    height: 180px;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }
            .archive-item-area {
                text-align: center;
                font-size: 16px;
                font-weight: normal;
                margin-top: 15px;
                color: #3E3E3E;
            }
            .archive-title {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 100%;
            }
            .archive-item-link {
                &:hover {
                    opacity: 0.6;
                }
            }
            .archive-item-more {
                font-size: 12px;
                color: #8B7850;
                display: block;
                text-align: right;
                margin-top: 12px;
                &:hover {
                    opacity: 0.6;
                }
                svg {
                    vertical-align: text-top;
                }
                span {
                    padding-right: 5px;
                    font-weight: bold;
                }
            }
        }
        .news-list {
            margin: 60px 210px;
        }
        .news-item-wrap {
            display: flex;
            justify-content: space-between;
            .news-item-header {
                display: flex;
                width: 85%;
            }
            .news-item-date, .cat-list {
                width: 20%;
            }
            .cat-list {
                margin: 0 15px;
                padding: 0;
                list-style: none;
                text-align: left;
                a {
                    border-radius: 16px;
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 13px;
                    padding: 6px 28px;
                }
            }
            .news-item-title {
                font-size: 16px;
                line-height: 1.3;
                width: 45%;
                text-align: left;
            }
            .news-item-text {
                padding-top: 35px;
                letter-spacing: 0.08em;
                line-height: 1.25;
            }
            .news-item-link {
                width: 15%;
            }
        }
    }
}
