
.company {
    margin-bottom: 90px;
    p {
        margin: 0;
    }
    .vision-wrap {
        margin-top: 100px;
        position: relative;
        .text-box {
            background-color: #F25043;
            color: #ffffff;
            height: auto;
            opacity: 0.9;
            position: absolute;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            padding: 40px 50px;
            letter-spacing: 0.08em;
            line-height: 1.35;
            span {
                font-size: 16px;
            }
            h3 {
                font-size: 22px;
                margin-bottom: 25px;
            }
            p {
                font-size: 16px;
            }
        }
    }
    .mission-wrap {
        margin-top: 200px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        .text-box {
            background-color: #F25043;
            color: #ffffff;
            height: auto;
            opacity: 0.9;
            position: absolute;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            padding: 40px 50px;
            letter-spacing: 0.08em;
            line-height: 1.35;
            span {
                font-size: 16px;
            }
            h3 {
                font-size: 22px;
                margin-bottom: 25px;
            }
            p {
                font-size: 16px;
            }
        }
    }
    .massage-wrap {
        height: auto;
        background-color: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin: 0 auto;
        padding: 60px 30px 80px 30px;
        h2 {
            color: #8B7850;
            font-size: 30px;
            text-align: center;
            line-height: 1.36;
            font-weight: 400;
        }
        h3 {
            font-weight: 400;
            margin-top: 60px;
        }
        p {
            line-height: 1.37;
            margin-top: 20px;
        }
    }
    .name-wrap {
        margin-top: 50px;
        img {
            width: 40%;
            border-radius: 20px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
        .namebox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            span {
                font-size: 14px;
            }
            .name {
                font-size: 30px;
                margin: 10px 0;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: -15px;
                    display: inline-block;
                    height: 1.5px; 
                    width: 300px;
                    -moz-transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    transform: translate(-50%); 
                    background-color: #8B7850; 
                  }
                  .pr-10 {
                      padding-right: 10px;
                  }
            }
        }
    }
    .overview {
        margin: 0 auto;
        margin-top: 100px;
    }
    
    table.company-table {
        width: 100%;
        margin-top: 100px;
        border-collapse: collapse;
        box-sizing: border-box;
        font-size: 16px;
        
        th {
            border-bottom: solid 1.5px #8B7850;
            padding: 27px 0;
        }
    }
    .access-wrap {
        margin-top: 85px;
        > span {
            display: block;
            color: #F25043;
            font-size: 14px;
            text-align: center;
            position: relative;
            letter-spacing: 0.08em;
            line-height: 1.35;
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -28px;
                display: inline-block;
                width: 180px;
                height: 1px; 
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translate(-50%); 
                background-color: #383838; 
            }
        }
        h4 {
            color: #3E3E3E;
            text-align: center;
            margin-top: 64px;
            letter-spacing: 0.08em;
            line-height: 1.35;
            font-weight: bold;
            font-size: 20px;
        }
        .img-map {
            width: 80%;
            position: relative;
            p {
                font-weight: 500;
                margin-top: 40px;
            }
        }
    }
    
    .button {
        display: inline-block;
        -webkit-perspective: 300px;
        perspective: 300px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        transition: all .3s;
        box-sizing: border-box;
        overflow: hidden;
        border: 1px solid #8B7850;
        color: #8B7850;
        background-color: #ffffff;
        &:hover {
            span {
                &::after {
                    content: "";
                    position: absolute;
                    background: center/28px 6px no-repeat url(../../common/img/map-arrow-white.svg); 
                }
            }
        }
        span {
            &::after {
                content: "";
                position: absolute;
                background: center/28px 6px no-repeat url(../../common/img/map-arrow.svg); 
                width: 28px;
                height: 6px;
                top: 50%;
            }
        }
    }
    .pl-20 {
        padding-left: 20px;
    }
}

@media (max-width: 767px){
    .company.container {
        width: 100%;
        padding: 0;
    }
    .company {
        .vision-wrap {
            margin-top: 100px;
            margin-bottom: 110px;
            position: relative;
            height: 300px;
            overflow-x: hidden;
            img {
                height: 240px;
            }
            .text-box {
                width: 70%;
                background-color: #F25043;
                color: #ffffff;
                height: auto;
                opacity: 0.9;
                position: absolute;
                top: 100px;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                padding: 20px 30px;
                span {
                    font-size: 14px;
                }
                h3 {
                    font-size: 16px;
                }
                p {
                    font-size: 13px;
                }
            }
        }
        .mission-wrap {
            margin-top: 100px;
            margin-bottom: 110px;
            position: relative;
            display: flex;
            justify-content: flex-end;
            height: 440px;
            overflow-x: hidden;
            img {
                height: 240px;
            }
            .text-box {
                width: 70%;
                background-color: #F25043;
                color: #ffffff;
                height: auto;
                opacity: 0.9;
                position: absolute;
                top: 100px;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                padding: 20px 30px;
                span {
                    font-size: 14px;
                }
                h3 {
                    font-size: 16px;
                }
                p {
                    font-size: 13px;
                }
            }
        }
        .massage-wrap {
            width: 300px;
            box-sizing: border-box;
            height: auto;
            background-color: #ffffff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            margin: 0 auto;
            padding: 40px 30px 80px 30px;
            h2 {
                color: #8B7850;
                font-size: 25px;
                text-align: center;
            }
        }
        .name-wrap {
            display: block;
            text-align: center;
            img {
                width: 100%;
            }
            .namebox {
                margin-top: 15px;
                span {
                    font-size: 14px;
                }
                .name {
                    font-size: 20px;
                    margin: 10px 0;
                    position: relative;
                    &:before {
                        left: 50%;
                        width: 200px;
                    }
                    .pr-10 {
                        padding-right: 10px;
                    }
                }
            }
        }
        .overview {
            padding: 0 20px;
        }
        .company-table {
            th {
                border-bottom: solid 1.5px #8B7850;
                box-sizing: border-box;
                padding: 20px 0;
                width: 100%;
                display: block;
                text-align: left;
            }
            td {
                box-sizing: border-box;
                padding: 20px 0;
                width: 100%;
                display: block;
                text-align: left;
                a {
                    text-decoration: none;
                    color: #3E3E3E;
                }
            }
        }
        .button {
            position: absolute;
            font-size: 12px;
            width: 51%;
            bottom: 27%;
            left: 49%;
            background-color: #8B7850;
            color: #fff;
            padding: 0 20px;
            text-align: center;
            span {
                &::after {
                    right: 10%;
                }
            }
        }
    }
    .access-wrap {
        .img-map {
            margin: 50px auto 0 auto;
            position: relative;
            p {
                font-size: 14px;
                font-weight: 500;
                margin-top: 40px;
                a {
                    text-decoration: none;
                    color: #3E3E3E;
                }
            }
        }
    }
}


@media screen and (min-width:768px) and ( max-width:1023px) {
    .company {
        .mission-wrap {
            margin: 200px 0;
            img {
                width: 80%;
            }
            .text-box {
                top: 45px;
                width: 50%;
                left: 0;
            }
        }
        .vision-wrap {
            img {
                width: 80%;
            }
            .text-box {
                top: 45px;
                width: 50%;
                right: 0;
            }
        }
        .massage-wrap {
            width: 65%;
            h2 {
                font-size: 30px;
            }
        }
        .overview {
            width: 80%;
            margin: 0 auto;
        }
        .company-table {
            width: 100%;
            border-collapse: collapse;
            box-sizing: border-box;
            font-size: 16px;
            tr {
                &:first-child th{
                    border-top: solid 1.5px #8B7850;
                }
                &:first-child td{
                    border-top: solid 1.5px #CCCCCC;
                }
            }
            th {
                width: 200px;
                padding: 27px 0;
                text-align: center;
            }
            td {
                width: 680px;
                border-bottom: solid 1.5px #CCCCCC;
            }
        }
        .name-wrap {
            display: flex;
            justify-content: center;
            .namebox {
                display: flex;
                margin-left: 80px;
                flex-direction: column;
                justify-content: center;
                span {
                    font-size: 14px;
                }
                .name {
                    font-size: 30px;
                    margin: 10px 0;
                    position: relative;
                    &:before {
                        left: 71%;
                    }
                    .pr-10 {
                        padding-right: 10px;
                    }
                }
            }
        }
        .access-wrap {
            .img-map {
                margin: 75px auto 0 auto;
                p {
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 40px;
                }
            }
        }
        .button {
            font-size: 14px;
            width: 21%;
            bottom: 28%;
            left: 79%;
            position: absolute;
            text-align: center;
        }
    }
}


@media (min-width: 1024px) {
    .company {
        .vision-wrap {
            .text-box {
                width: 475px;
                top: 45px;
                left: 610px;
                span {
                    font-size: 16px;
                }
                h3 {
                    font-size: 22px;
                }
                p {
                    font-size: 16px;
                }
            }
        }
        .mission-wrap {
            margin-top: 100px;
            margin-bottom: 200px;
            position: relative;
            display: flex;
            justify-content: flex-end;
            .text-box {
                width: 475px;
                top: 45px;
                right: 610px;
                span {
                    font-size: 16px;
                }
                h3 {
                    font-size: 22px;
                }
                p {
                    font-size: 16px;
                }
            }
        }
        .massage-wrap {
            width: 900px;
            h2 {
                font-size: 30px;
            }
        }
        .overview {
            width: 880px;
            margin: 0 auto;
        }
        .company-table {
            width: 100%;
            border-collapse: collapse;
            box-sizing: border-box;
            font-size: 16px;
            tr {
                &:first-child th{
                    border-top: solid 1.5px #8B7850;
                }
                &:first-child td{
                    border-top: solid 1.5px #CCCCCC;
                }
            }
            th {
                width: 200px;
                padding: 27px 0;
                text-align: center;
            }
            td {
                width: 680px;
                border-bottom: solid 1.5px #CCCCCC;
                padding: 20px 20px 20px 60px;
            }
        }
        .name-wrap {
            display: flex;
            justify-content: center;
            .namebox {
                display: flex;
                margin-left: 80px;
                flex-direction: column;
                justify-content: center;
                span {
                    font-size: 14px;
                }
                .name {
                    font-size: 30px;
                    margin: 10px 0;
                    position: relative;
                    &:before {
                        left: 71%;
                    }
                    .pr-10 {
                        padding-right: 10px;
                    }
                }
            }
        }
        .access-wrap {
            .img-map {
                margin: 75px auto 0 auto;
                p {
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 40px;
                }
            }
        }
        .button {
            position: absolute;
            width: 29.5%;
            bottom: 28%;
            left: 70.6%;
            cursor: pointer;
            text-align: center;

            &::before, &::after {
                transition: all .3s;
                box-sizing: border-box;
                position: absolute;
                z-index: -1;
                display: block;
                content: '';
            }
            &:hover {
                color: #fff;
                &::after {
                    top: 0;
                    left: 0;
                    background-color: #8B7850;
                }
            }
            &::after {
                top: -100%;
                left: -100%;
                width: 100%;
                height: 100%;
            }
            span {
                &::after {
                    right: 20%;
                }
            }
        }
    }
}