.p-page {
    background: #f9f4ef;

    &--article {
        &--wrap {
            padding: 80px 0;
        }
        max-width: 1180px;
        margin: 0 auto;
        background-color: #fff;
        &--inner {
            padding: 80px 0 105px;
            max-width: calc(100% - 40px);
            width: 900px;
            margin: 0 auto;

            @include tab {
                padding: 40px 0 50px;
            }
        }

        &--title {
            font-size: 28px;
            font-weight: 700;
            line-height: (50/28);

            @include tab {
                font-size: 22px;
            }
        }

        &--content {
            margin-top: 40px;
            font-size: 16px;
            line-height: (28.8/16);

            @include tab {
                font-size: 14px;
            }

            & > :first-child {
                margin-top: 0 !important;
            }
            & > :last-child {
                margin-bottom: 0 !important;
            }

            .wp-block-table {
                table {
                    line-height: (22/16);
                    font-weight: 700;
                    letter-spacing: 0.08em;
                    border-left: 1px solid #9a9a9a;
                    border-right: 1px solid #9a9a9a;
                    border-bottom: 1px solid #9a9a9a;
                    td {
                        border-top: 1px solid #9a9a9a;
                        padding: 19px 45px;
                        @include tab {
                            padding: 19px;
                            display: block;
                        }
                        &:first-of-type {
                            background: #f5f5f5;
                        }
                        &:not(:first-of-type) {
                            @include pc {
                                border-left: 1px solid #9a9a9a;
                            }
                        }
                    }
                }
            }

            h2 {
                margin: 2em 0;
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: (27/20);
                border-bottom: 1px solid #3E3E3E;
                padding-bottom: 5px;

                @include tab {
                    font-size: 18px;
                }
            }

            p{
                margin: 1em 0;
            }
        }
    }

    &--contact{
        &--heading{
            background: url(../../common/img/page/contact_heading_bg.jpg) center/cover no-repeat;
            text-align: center;
            color: #fff;
            padding: 80px 20px;

            @include tab {
                padding: 40px 20px;
            }

            &--title{
                font-size: 34px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: (61.2/34);
                @include tab {
                    font-size: 28px;
                }
            }

            &--txt{
                margin-top: 20px;
                font-size: 16px;
                line-height: (28.8/16);

                @include tab {
                    font-size: 14px;
                }
            }
        }
        &--body{
            background-color: #fff;
            padding: 80px 0 100px;

            @include tab {
                padding: 40px 0 50px;
            }

            &--form{
                max-width: calc(100% - 40px);
                width: 650px;
                margin: 0 auto;
            }
        }
    }

    &--ad {
      &--eyecatch,
      &--text,
      &--table,
      &--map {
        margin-bottom: 60px!important;
      }
      &--textarea {
        height: 200px!important;
      }
    }
}
